import * as React from "react";
import "./Customer.css";

const Customer=  React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const customerimg = require("../../assets/images/gangsta-810777_1280.png");
  const [activeSlide, setActiveSlide] = React.useState(0);

  const slides = [
    {
      rating: 4,
      title: "Kleiner Titel",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, facilis!",
      name: "Name",
      imgSrc: customerimg,
    },
    {
      rating: 5,
      title: "Kleiner Titel Titel",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, facilis!",
      name: "Stefan",
      imgSrc: customerimg,
    },
  ];

  const handlePrevClick = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleNextClick = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  return (
    <section className="customer_experience" ref={ref}>
      <div className="customer_inner_wrap">
        <div className="customer_img_container">
          <img src={customerimg} alt="customerimg" />
        </div>
        <div className="customer_content_container">
          <h1>
            Our Customer <span className="customer_title_span">Love Us.</span>
          </h1>
          <h2>See What They Say!</h2>
          <p className="customer_paragraph">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque commodi velit quo fuga molestiae voluptates, excepturi ducimus magni laudantium nobis. Molestiae architecto, nesciunt inventore nobis labore in deleniti quia reiciendis!
          </p>
          {slides.map((slide, index) => (
            <div className={`rating_carousel ${index === activeSlide ? "active" : ""}`} key={index}>
              <div className="carousel_slide">
                <div className="rating">
                  {Array(slide.rating).fill(<span className="star">&#9733;</span>)}
                </div>
                <div className="content">
                  <h4 className="customer_header">{slide.title}</h4>
                  <p className="customer_words">{slide.content}</p>
                </div>
              </div>
              <div className="profile_container">
                <div className="profile">
                  <img src={slide.imgSrc} alt="Profilbild" className="profile-pic" />
                  <p className="name">{slide.name}</p>
                </div>
                <div className="carousel_controls">
                  <button className="prev" onClick={handlePrevClick}>
                    {/* SVG for previous button */}
                  </button>
                  <button className="next" onClick={handleNextClick}>
                    {/* SVG for next button */}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default Customer;

import * as React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer: React.FC = () => {
  const bubbles = Array.from({ length: 128 }, (_, index) => index);

  return (
    <footer className="footer">
      
      <div className="container">
        <div className="footer-links">
          <Link to="/datenschutz" className="footer-link">
            Datenschutz
          </Link>
          <Link to="/impressum" className="footer-link">
            Impressum
          </Link>
          <Link to="/gallerypage" className="footer-link">
            Galerie
          </Link>
          <Link to="/artists" className="footer-link">
            Künstler
          </Link>
        </div>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/IhrUnternehmen"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/IhrUnternehmen"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaInstagram />
          </a>
        </div>
        <span className="text-muted">
          © 2024 Ihr Unternehmen Name. Alle Rechte vorbehalten.
        </span>
      </div>
    </footer>
  );
};

export default Footer;

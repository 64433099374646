import * as React from "react";

interface TextCardProps {
  title: string;
  content: string;
}

const TextCard: React.FC<TextCardProps> = ({ title, content }) => {
  return (
    <div className="textCard">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default TextCard;

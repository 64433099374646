export interface MenuDataInterface {
  title: string;
  url: string;
  cName: string;
  icon: string;
  ref: string
}

export const MenuData: MenuDataInterface[] = [
  {
    title: "Home",
    url: "#",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: 'HomeDivRef',
  },
  {
    title: "Work",
    url: "#",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: 'WorkDivRef'
  },
  {
    title: "Artist",
    url: "#",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: 'ArtistDivRef'
  },
  {
    title: "Customer Experience",
    url: "#",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: 'CustomerDivRef'
  },
  {
    title: "Studio",
    url: "#",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: 'StudioDivRef'
  },
  {
    title: "Gallery",
    url: "gallerypage",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
    ref: ''
  },
];

import * as React from "react";
import Header from "../containers/Header/Header";

interface HomeProps {
  setIsNavbarHidden: (value: boolean) => void;
}

const Home = React.forwardRef<HTMLDivElement, HomeProps>((props, ref) => {
  return <Header {...props} ref={ref} />;
});

export default Home;

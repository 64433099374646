import * as React from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./CoverUps.css";

gsap.registerPlugin(ScrollTrigger);

interface CoverUpsImageProps {
  addtoRefs: (element: HTMLDivElement) => void;
  beforeimages: string[];
  afterimages: string[]; 
}

class CoverUpsImage extends React.Component<CoverUpsImageProps> {
  render() {
    const { addtoRefs, beforeimages, afterimages } = this.props;

    return (
      <div className="box-container">
        {beforeimages.map((before_images, index) => (
          <div className="innerbox-container">
          <div key={index} ref={addtoRefs} className="box">
            <div className="image-box">
            <img src={before_images} alt={`Image ${index + 1}`} />
            <img src = {afterimages[index]} alt={`Image ${index + 2}`} />
            </div>
          </div>
          </div>
        ))}
      </div>
    );
  }
}

const CoverUps: React.FC = () => {

  const before_images = [
     require("../../assets/images/black_art_arm_tattoo.jpg"),
  require("../../assets/images/black_art_back_woman_tattoo.jpg")
 
   
  ];

  const after_images=[
   require("../../assets/images/black_art_back_woman_tattoo.jpg"),
      require("../../assets/images/pexels-ralph-rabago-3214681.jpg")
  ]

  const ref = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    ref.current.forEach((el) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          left:0,

          duration: 0.8,
          scrollTrigger: {
            trigger: el,

            start: "top bottom-=100",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  const addtoRefs = (el: HTMLDivElement) => {
    if (el && !ref.current.includes(el)) {
      ref.current.push(el);
    }
  };

  const titleParagraphRef = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (titleParagraphRef.current) {
        gsap.to(titleParagraphRef.current, {
          scrollTrigger: {
            trigger: titleParagraphRef.current,
            start: "top center+=100",
            end: "+=400",
            pin: true,

            pinSpacing: false,
            toggleActions: "play none none reverse",
            scrub: true,
          },
          rotation: 360,
          duration: 2
        });
        ScrollTrigger.refresh();
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="coverups_container">
      <div className="divSpace">
        <h1 className="title_coverups" ref={titleParagraphRef}>
          Cover-Ups
        </h1>
      </div>
      <CoverUpsImage beforeimages={before_images} afterimages={after_images} addtoRefs={addtoRefs} />
    </div>
    
  );
};

export default CoverUps;

import * as React from "react";
import  './GalleryPage.css';
import OverlayNavbar from "../../components/OverlayNavbar/OverlayNavbar";
import { Button, ButtonSize } from "../../components/Button/Button";
import TitleText from "../../components/TitleText/TitleText";
const titleimg = require("../../assets/images/black_art_arm_tattoo.jpg");
const armtattoo = require("../../assets/images/black_art_back_woman_tattoo.jpg");
const armaattoosecond = require("../../assets/images/black_art_chest_tattoo.jpg");
const poseidon = require("../../assets/images/black_art_arm_tattoo.jpg");
const rueckentattooo = require("../../assets/images/pexels-ralph-rabago-3214681.jpg");

// Array mit allen Bildern erstellen
const allImages = [
  titleimg,
  armtattoo,
  rueckentattooo,
  rueckentattooo,
  poseidon,
  rueckentattooo,
  titleimg,
  armtattoo,
  poseidon,
  titleimg,
  armaattoosecond,
  armtattoo,
  poseidon,
  armaattoosecond,
  poseidon,
  titleimg,
  armaattoosecond,
  armtattoo,
  poseidon,
  // Fügen Sie hier zusätzliche Bilder hinzu
];

const GalleryPage: React.FC = () => {
  const initialImageCount = 9; // Anzahl der Bilder, die anfangs angezeigt werden
  const [imageCount, setImageCount] = React.useState(initialImageCount);
  const [viewMore, setViewMore] = React.useState(true); // Zustand für den Button-Text und die Funktion

  const handleViewMoreLess = () => {
    setImageCount(viewMore ? allImages.length : initialImageCount);
    setViewMore(!viewMore);
  };
  const [clicked, setClicked] = React.useState(false);
  const WorkDivRef = React.createRef<HTMLDivElement>();
  const HomeDivRef = React.createRef<HTMLDivElement>();
  const ArtistDivRef = React.createRef<HTMLDivElement>();
  const StudioDivRef = React.createRef<HTMLDivElement>();
 
  const [isNavbarHidden, setIsNavbarHidden] = React.useState(false);
  const [buttonSize, setButtonSize] = React.useState<ButtonSize>("btn--large");
  const titleStyle: React.CSSProperties = {
    fontSize: '4rem',
    fontWeight: 'bold',
  };
  return (
    <div className="gallery-container">
      <div className="top">{!isNavbarHidden && <OverlayNavbar
        clicked={clicked}
        setClicked={setClicked}
    
        refs={{ WorkDivRef, HomeDivRef, ArtistDivRef, StudioDivRef }}
      />}</div>
      <TitleText text="OUR WORK" style={titleStyle}/>

      <div className="gallery">
        {allImages.slice(0, imageCount).map((img, i) => (
          <div className="gallery-item" key={i}>
            <img src={img} alt={`Arbeit ${i + 1}`} />
          </div>
        ))}
      </div>

      <Button
          buttonStyle="btn--outline"
          buttonSize={buttonSize}
          onClick={handleViewMoreLess}
        >
        {viewMore ? "VIEW MORE" : "SHOW LESS"}
        </Button>
    </div>
  );
};

export default GalleryPage;

import * as React from "react";
import "./Studio.css";
import TextCard from "../../components/TextCard/TextCard";





const Studio =  React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  
  return (
    <div className="studio_container" ref={ref}>
      <h1 className="studio_title">ABOUT STUDIO</h1>
      <div className="cards_wrapper">
        <div className="inner_card_wrapper">
          <TextCard 
          title="Unsere Mission" 
          content="Wir streben danach, erstaunliche Kunst zu schaffen und mit der Gemeinschaft zu interagieren." 
         />
          <TextCard 
          title="Unsere Mission" 
          content="Wir streben danach, erstaunliche Kunst zu schaffen und mit der Gemeinschaft zu interagieren." 
        />
         <TextCard 
          title="Unsere Mission" 
          content="Wir streben danach, erstaunliche Kunst zu schaffen und mit der Gemeinschaft zu interagieren." 
         />
          <TextCard 
          title="Unsere Mission" 
          content="Wir streben danach, erstaunliche Kunst zu schaffen und mit der Gemeinschaft zu interagieren." 
         />
        </div>
        
        </div>
      </div>

  );
});

export default Studio;

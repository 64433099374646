import * as React from 'react';

type TitleTextProps = {
  text: string;
  style?: React.CSSProperties; // für inline-Styling
  className?: string; // für CSS-Klassen
};
const titleStyles: React.CSSProperties = {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
  };

const TitleText: React.FC<TitleTextProps> = ({ text, style, className }) => {
  return (
    <h1 className={className} style={{...titleStyles,...style}}>
      {text}
    </h1>
  );
};

export default TitleText;

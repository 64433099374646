import * as React from "react";
import Work from "./pages/Work/Work";
import Studio from "./pages/Studio/Studio";
import { useState } from "react";
import OverlayNavbar from "./components/OverlayNavbar/OverlayNavbar";
import Home from "./pages/Home";
import "./App.css";
import Artists from "./pages/Artists/Artists";
import Customer from "./pages/Customer/Customer";
import SequenceText from "./components/TextAnimations/SequenceText/SequenceText";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./containers/Footer/Footer";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import { homedir } from "os";
import Contact from "./pages/Contact/Contact";
import AnimationText from "./components/TextAnimations/SequenceText/AnimationText";

const App: React.FC = () => {
  const getTextRef = React.useRef<HTMLParagraphElement>(null);
  const aTextRef = React.useRef<HTMLParagraphElement>(null);
  const tattooTextRef = React.useRef<HTMLParagraphElement>(null);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);
const textConst = "text";
  const artist2 = require("./assets/images/gangsta-810777_1280.png");
  const artist1 = require("./assets/images/portrait-3052641_1280.jpg");
  const smiley = require("./assets/images/smiley.png");
  const artist1Text = "Hello I am Alfonso";
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const textStyle: React.CSSProperties = {
    fontFamily: "fantasy",
    letterSpacing: "10px",
    color: "white",
    position: "absolute",
    fontWeight: 900,
    margin: 0,
  };
  const [getTextStyle, setGetTextStyle] = useState<React.CSSProperties>({});
  const [aTextStyle, setATextStyle] = useState<React.CSSProperties>({});
  const [tattooTextStyle, setTattooTextStyle] = useState<React.CSSProperties>({});
  const WorkDivRef = React.createRef<HTMLDivElement>();
  const HomeDivRef = React.createRef<HTMLDivElement>();
  const ArtistDivRef = React.createRef<HTMLDivElement>();
  const StudioDivRef = React.createRef<HTMLDivElement>();
  const CustomerDivRef = React.createRef<HTMLDivElement>();
  
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    // Funktion zur Anpassung der Schriftgröße
    const adjustFontSize = () => {
      const screenWidth = windowWidth;
      
      setGetTextStyle({ //screenWidth > 1768 ? '15rem' : (screenWidth > 1000 ? '12rem' : (screenWidth < 1000 ? '7rem': '5rem')),
        top: "10%"
      });
    
      setATextStyle({
       // screenWidth > 1768 ? '13rem' : (screenWidth > 1000 ? '15rem' :(screenWidth <= 1000 ? '10rem': '5rem')),
        top: "35%",
        left: "35%",
      });
    
      setTattooTextStyle({
        // screenWidth > 1768 ? '20rem' : (screenWidth > 1000 ? '18rem' : (screenWidth <= 1000 ? '12rem': '5rem')),
        bottom: (screenWidth > 1000 ? '0%' : '10%'),
      });
    };

    // Event-Listener für Änderungen der Fenstergröße
    window.addEventListener('resize', adjustFontSize);

    // Anfangseinstellung
    adjustFontSize();

    // Cleanup-Funktion
    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, []);
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Anfangsposition für "GET" und "TATTOO" setzen
    gsap.set(getTextRef.current, { left: '-100%' });
    gsap.set(tattooTextRef.current, { right: '-100%' });
    gsap.set(aTextRef.current, { y: 50, autoAlpha: 0 });
    // Timeline für "GET" mit onComplete-Callback
    gsap.to(getTextRef.current, {
      scrollTrigger: {
        trigger: getTextRef.current,
        start: "top center",
        toggleActions: "play none none none"
      },
      left: '10%',
      duration: 1,
      ease: 'back.out',
      onComplete: () => {
        gsap.to(aTextRef.current, {
          duration: 1,
          y: 0, // Bewegt "A" nach oben in seine endgültige Position
          autoAlpha: 1, // Fadet "A" ein
          ease: 'power1.out'
        });
        // Starten der Animation für "TATTOO" nach Abschluss von "GET"
        gsap.to(tattooTextRef.current, {
          right: '5%',
          duration: 1.5,
          ease: 'back.out'
        });
      }
    });

  }, []);

  return (
  
    <div className="App">
      <div className="top">
        {!isNavbarHidden && <OverlayNavbar
        clicked={clicked}
        setClicked={setClicked}
        refs={{ WorkDivRef:WorkDivRef, HomeDivRef:HomeDivRef,ArtistDivRef: ArtistDivRef, CustomerDivRef: CustomerDivRef,StudioDivRef: StudioDivRef }}
      />}
        <Home setIsNavbarHidden={setIsNavbarHidden} ref={HomeDivRef}/>
      </div>
      <Work ref={WorkDivRef}/>
      <Artists imgurl={artist1} artistText={artist1Text} isImageLeft={false} ref={ArtistDivRef} />
      <Artists imgurl={artist2} artistText={artist1Text} isImageLeft={true} />
      {/* <div className="sequencetext_animation">
      <SequenceText className={`get ${textConst}`} text="GET" styles={[textStyle, getTextStyle]} ref={getTextRef} />

      <SequenceText className={`a ${textConst}`} text="A" styles={[textStyle, aTextStyle]} ref={aTextRef}/>
      
      <SequenceText className={`tattoo ${textConst}`} text="TATTOO" styles={[textStyle, tattooTextStyle]} ref={tattooTextRef} />
   
      <img className="smiley" src={smiley}></img>
      </div> */}
      <AnimationText/>
      <Customer ref={CustomerDivRef}/>
      <Studio ref={StudioDivRef}/>
      <Contact/>
      <Footer/>
    </div>

  );
};

export default App;

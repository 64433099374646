import * as React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";

type FormState = {
  emailSubmitted: boolean;
};

const Contact: React.FC = () => {
  const insticon = require("../../assets/icons/icons8-instagram.svg");
  const [formState, setFormState] = React.useState<FormState>({
    emailSubmitted: false,
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const formData = new FormData(e.currentTarget);
  
    try {
      const response = await fetch('/sendEmail.php', {
        method: 'POST',
        body: formData, // Verwenden Sie formData direkt
      });
  
      if (response.ok) {
        setFormState({ ...formState, emailSubmitted: true });
      } else {
        console.error('An error occurred while sending the email.');
      }
    } catch (error) {
      console.error('An error occurred while sending the email:', error);
    }
  };
  

  return (
    <section id="contact" className="contact-section">
      <div className="content-wrapper">
        <h5 className="section-heading">Let's Connect</h5>
        <p className="section-text">
          I'm currently looking for new opportunities, my inbox is always open.
          Whether you have a question or just want to say hi, I'll try my best
          to get back to you!
        </p>
        <div className="social-links">
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insticon.default} alt="Instagram Icon" />
          </a>
        </div>
      </div>
      <div className="form-wrapper">
        {formState.emailSubmitted ? (
          <p className="success-message">Email sent successfully!</p>
        ) : (
          <form className="contact-form" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="form-input"
                placeholder="jacob@google.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                required
                className="form-input"
                placeholder="Just saying hi"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="form-input"
                placeholder="Let's talk about..."
              ></textarea>
            </div>
            <div className="form-group">
            <button type="submit" className="submit-button">
              Send Message
            </button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;

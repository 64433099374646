import * as React from "react";
import { gsap } from "gsap";
import "./AnimationText.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AnimationText: React.FC = () => {
    const getTextRef = React.useRef<HTMLDivElement>(null);
    const aTextRef = React.useRef<HTMLDivElement>(null);
    const tattooTextRef = React.useRef<HTMLDivElement>(null);
    const smiley = require("../../../assets/images/smiley.png");

    React.useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
  
      gsap.timeline({
        scrollTrigger: {
          trigger: getTextRef.current!,
          start: "top center",
          markers: true,
          toggleActions: "play none none none"
        }
      })
      .fromTo(getTextRef.current, { autoAlpha: 0, x: '-100%' }, { autoAlpha: 1, x: '0%', duration: 1, ease: 'back.out' })
      .fromTo(aTextRef.current, { autoAlpha: 0, y: 50 }, { autoAlpha: 1, y: 0, duration: 0.5, ease: 'power1.out' }, '-=0.5') // Beginnt 0,5 Sekunden vor dem Ende von "GET"
      .fromTo(tattooTextRef.current, { autoAlpha: 0, x: '100%' }, { autoAlpha: 1, x: '0%', duration: 1.5, ease: 'back.out' }, '-=0.5');
      
  
    }, []);
  
    return (
      <div className="animation-text-container">
        <div ref={getTextRef} className="text-get">GET</div>
        <div ref={aTextRef} className="text-a">A</div>
        <div ref={tattooTextRef} className="text-tattoo">TATTOO</div>
        <img className="smiley" src={smiley} alt="Smiley" /> {/* Fügen Sie Ihr Bild hier ein */}

      </div>
    );
  };
  
  export default AnimationText;
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, ButtonSize } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import "./header.css";

interface HeaderProps {
  setIsNavbarHidden: (value: boolean) => void;
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(({ setIsNavbarHidden }, ref) => {
  const [showVideo, setShowVideo] = useState(false);
  const [buttonSize, setButtonSize] = useState<ButtonSize>("btn--medium");
  const showButton = () => {
    if (window.innerWidth <= 480) {
      setButtonSize("btn--small");
    } else if (window.innerWidth <= 960) {
      setButtonSize("btn--medium");
    } else {
      setButtonSize("btn--large");
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);

    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  return (
    <div className="header-container" ref={ref}>
      <img
        src={require("../../assets/images/tattoostudio.jpg")}
        alt="Cover"
        className={`cover-image ${showVideo ? "fade-out" : ""}`}
      />

      <video
        className={`background-video ${showVideo ? "fade-in" : ""}`}
        autoPlay
        muted
        loop
      >
        <source
          src={require("../../assets/videos/headervideo.mp4")}
          type="video/mp4"
        />
      </video>

      <div className={`overlay-text ${showVideo ? "hidden" : ""}`}>
        <h1 className="title_animation"> Mein Text über dem Video</h1>
        <Button
          buttonStyle="btn--outline"
          buttonSize={buttonSize}
          onClick={() => {
            setShowVideo(true);
            setIsNavbarHidden(true);
          }}
        >
          WATCH TRAILER
        </Button>
      </div>

      {showVideo && (
        <button
          className="close-button"
          onClick={() => {
            setShowVideo(false);
            setIsNavbarHidden(false);
          }}
        >
          X
        </button>
      )}
    </div>
  );
});

export default Header;

import * as React from "react";
import "./Button.css";

export type ButtonSize = "btn--small" | "btn--medium" | "btn--large";
export type ButtonStyle = "btn--primary" | "btn--outline" | "btn--test";

interface Props {
  children: any;
  onClick: any;
  type?: "submit" | "reset" | "button" | undefined;
  buttonStyle: ButtonStyle;
  buttonSize: ButtonSize;
}

export const Button: React.FC<Props> = ({
  children,
  type,
  onClick,
  buttonStyle = "btn--primary",
  buttonSize = "btn--medium",
  ...props
}) => {
  return (
    <button
      className={`btn ${buttonStyle} ${buttonSize}`}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

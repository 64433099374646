import * as React from "react";
import ImageGrid from "../../components/ImageGrid/ImageGrid";
import "./Work.css";
import CoverUps from "./CoverUps";

const Work = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const titleimg = require("../../assets/images/black_art_arm_tattoo.jpg");
  const armtattoo = require("../../assets/images/black_art_back_woman_tattoo.jpg");
  const armaattoosecond = require("../../assets/images/black_art_chest_tattoo.jpg");
  const poseidon = require("../../assets/images/black_art_arm_tattoo.jpg");
  const rueckentattooo = require("../../assets/images/pexels-ralph-rabago-3214681.jpg");

  const images = [
    { src: titleimg, alt: "Schwarze Kunst Arm Tattoo" },
    { src: armtattoo, alt: "Schwarze Kunst Rücken Frau Tattoo" },
    { src: armaattoosecond, alt: "Schwarze Kunst Brust Tattoo" },
    { src: poseidon, alt: "Poseidon Tattoo am Arm" },
    { src: rueckentattooo, alt: "Rücken Tattoo" },
  ];

  React.useEffect(() => {
    // Ensure the DOM is loaded
    const scrollers = document.querySelectorAll(".scroller");

    // Check for user's preference for reduced motion
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation(scrollers);
    }
  }, []);

  const addAnimation = (scrollers: NodeListOf<Element>) => {
    scrollers.forEach((scroller) => {
      scroller.setAttribute("data-animated", "true");

      const scrollerInner = scroller.querySelector(".scroller__inner");
      if (scrollerInner) {
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true) as HTMLElement;
          duplicatedItem.setAttribute("aria-hidden", "true");
          scrollerInner.appendChild(duplicatedItem);
        });
      }
    });
  };
  return (
    <div className="work" ref={ref}>
      <div className="darkart_container">
        <div className="title">
          <h1>Dark Art</h1>
          <div className="description">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit veritatis fugiat fugit, ab porro officiis sunt optio
              possimus magni sequi.
            </p>
          </div>
        </div>
        {/* <ImageGrid /> */}
      </div>
      <div className="art_container">
        <div className="scroller" data-direction="right" data-speed="slow">
          <div className="scroller__inner">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className="gallery-image"
              />
            ))}
          </div>
        </div>
      </div>

      <CoverUps />
    </div>
  );
});

export default Work;

import * as React from "react";
import "./Artists.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Button, ButtonSize } from "../../components/Button/Button";

gsap.registerPlugin(ScrollTrigger);

interface Props {
  imgurl: any;
  artistText: any;
  isImageLeft: any;
}

const Artists = React.forwardRef<HTMLDivElement, Props>(
  ({ imgurl, artistText, isImageLeft }, ref) => {
    const artistRef = React.useRef(null);
    const textRef = React.useRef<HTMLDivElement>(null);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    // Responsive Button-Größen
    const updateButtonSize = () => {
      setWindowWidth(window.innerWidth);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateButtonSize);
      return () => window.removeEventListener("resize", updateButtonSize);
    }, []);

    React.useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);

      if (artistRef.current) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: artistRef.current,
            start: "top center",
            end: "+=120px",
            scrub: true,
            toggleActions: "restart pause resume reset",
          },
        });
        tl.to(artistRef.current, { opacity: 1, duration: 1 });
      }
    }, []);

    React.useEffect(() => {
      const text = artistText;
      const textContainer = textRef.current;

      if (textContainer) {
        textContainer.textContent = "";

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: textContainer,
            start: "top+=50 center",
            markers: true,
            toggleActions: "play none none none",
          },
        });

        for (let i = 0; i < text.length; i++) {
          tl.to(
            textContainer,
            {
              textContent: text.slice(0, i + 1),
              duration: 0.0025,
              ease: "none",
              visibility: "visible",
            },
            `+=0.1`
          );
        }
      }
    }, []);

    const buttonSize: ButtonSize = windowWidth > 768 ? "btn--large" : "btn--medium";

    return (
      <div ref={ref} className={`artists ${isImageLeft ? 'reverse' : ' '}`}>
        <div className={`bio`}>
          <h1 className="bio__title" ref={textRef}>
            {artistText}
          </h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, in
            nostrum saepe facilis odit, praesentium aut exercitationem et
            perspiciatis, quia eos id impedit necessitatibus dolore totam
            incidunt accusantium quo cum?
          </p>
          <Button
            buttonStyle="btn--outline"
            buttonSize={buttonSize}
            onClick={() => alert("Kontaktieren Sie uns!")}
          >
            CONTACT
          </Button>
          <Button
            buttonStyle="btn--primary"
            buttonSize={buttonSize}
            onClick={() => alert("Unsere Designs ansehen!")}
          >
            DESIGNS
          </Button>
        </div>
        <div className={`artist__one`}>
          <img ref={artistRef} className="artist__one_image" src={imgurl} alt="Artist" />
        </div>
      </div>
    );
  }
);

export default Artists;

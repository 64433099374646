import * as React from "react";
import { useEffect, useState } from "react";
import { MenuData, MenuDataInterface } from "./MenuData/MenuData";
import "./OverlayNavbar.css";
import { useLocation, useNavigate } from "react-router-dom";
type NavbarProps = {
  clicked: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  refs: {
    [key: string]: React.RefObject<HTMLDivElement>;
  };
};


const OverlayNavbar: React.FC<NavbarProps> = ({ clicked, setClicked, refs }) => {
  const OpenIcon = require("../../assets/icons/tattogerät_white.svg");
  const CloseIcon = require("../../assets/icons/close_icon.svg");
  const location = useLocation();
  const [navbarShrink, setNavbarShrink] = useState<boolean>(false);
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };
  // Bestimmen, ob sich der Benutzer auf der Gallery-Seite befindet
  const onGalleryPage = location.pathname === '/gallerypage';
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setNavbarShrink(true);
    } else {
      setNavbarShrink(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (item: MenuDataInterface) => {
    if (item.ref && refs[item.ref]) {
      scrollToSection(refs[item.ref]);
    } else if (item.url && item.url !== "#") {
      window.location.href = `/${item.url}`;
    }
  };

  return (
    <nav className={`navbaritems ${navbarShrink ? "shrink" : ""}`}>
      <h1>Logo</h1>
      <div className="menu-icons" onClick={() => setClicked(!clicked)}>
        {clicked ? (
          <img src={CloseIcon.default} style={{ width: "80px", height: "60px" }} />
        ) : (
          <img src={OpenIcon.default} style={{ width: "80px", height: "60px" }} />
        )}
      </div>
      <ul className={clicked ? "overlay-nav-menu active" : "overlay-nav-menu"}>
        {onGalleryPage ? (
          <li onClick={navigateHome}>
            <a className="nav-links">Home</a>
          </li>
        ) : (
          MenuData.map((item, index) => (
            <li key={index} onClick={() => handleClick(item)}>
              <a className={item.cName}>{item.title}</a>
            </li>
          ))
        )}
      </ul>
    </nav>
  );
};

export default OverlayNavbar;
